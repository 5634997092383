export const ZERO = 0;

export const ONE = 1;

export const THREE = 3;

export const NOTIFICATION_MIN_COUNT = 0;

export const PRODUCTION = 'production';

export const NEGATIVE_ONE = -1;
export const FOUR = 4;
export const TWO = 2;
export const FIVE = 5;
export const ADMIN = 'admin';
export const STUDIO = 'studio';
export const LOCAL_HOST = 'localhost';
export const REDIRECT_URL_COOKIE_KEY = 'redirectURL';
export const LAST_TWO_IN_ARRAY = -2;
export const getDomain = () => {
  if (window.location.hostname === LOCAL_HOST) {
    return 'devzinier.net';
  }
  return window.location.hostname.split('.').slice(LAST_TWO_IN_ARRAY).join('.');
};

export const DEFAULT_USER_APP = 'user';
export const DECIMAL_EIGHTY = 0.80;

export const FLOAT_LEFT_CLASS = 'float-left';
export const LOCAL = 'local';

export const isDev =
    () => window.location.hostname.split('.').length === FIVE ||
        window.location.hostname === LOCAL_HOST;

export const isLocal = (orgId) => orgId === LOCAL;

export const SENDBIRD_CHAT = '/chat';

export const NUMBER9000000000000000 = 9000000000000000;

export const NUMBER1000000000000000 = 1000000000000000;

// eslint-disable-next-line max-len
export const SEND_BIRD_VAPID_KEY = 'BGAfgZoxkUgRQ7nOKrFvsaJPgOgp8yFGgjLV1yNIQWW7LrWAdjXPPkA61ThDcMbchGc-__BnoNJIhGHuBZ-ZufM';

export const SEND_BIRD_IV = '78285d4afda511ea';

export const LOCAL_STORAGE_DISPATCH_CONSOLE_PRESET_ID_KEY =
'dispatchConsoleViewPresetIdList';

export const LOCAL_STORAGE_CAPACITY_PRESET_ID_KEY = 'capcityViewPresetIdList';
export const GEN_AI_ENTITY_CONFIG = 'genAIConfig';
