
import axios from 'axios';
import { getDomain } from './constants';
export const getOrgDetailsForThreePartURL = async (orgId) => {
  let data = {};
  try {
    data = await axios({
      url: `https://loginapp.${getDomain()}/development/getorgdetails`,
      'Content-Type': 'application/json',
      method: 'POST',
      data: {
        orgId: orgId
      }
    });
  } catch {
    return null;
  }
  return data;
};

export const getOrgDetailsForFivePartURL = async (orgId, envFromUrl) => {
  let data = {};
  try {
    data = await axios({
      // eslint-disable-next-line max-len
      url: `https://loginapp.${getDomain()}/development/getorgdetails`,
      'Content-Type': 'application/json',
      method: 'POST',
      data: {
        orgId: `${envFromUrl}:${orgId}`
      }
    });
  } catch {
    return null;
  }
  return data;
};
