/* eslint max-statements: 0 */
/* eslint-disable max-len */
import ReactDOM from 'react-dom';
import 'pf-core/index.css';
import React, { Suspense } from 'react';
import { areCookiesAvailable } from './utilities/checkCookie';
// eslint-disable-next-line no-unused-vars
import { RedirectToLogin } from './features/Redirect/index';
import { Loader } from './assets/Loader/Loader';

const UserApplication = React.lazy(() => import('./features/App'));

function App () {
  if (!areCookiesAvailable()) {
    return <RedirectToLogin/>;
  }
  return (
    <Suspense fallback={ <Loader type='fullScreen'/>}>
      <UserApplication />
    </Suspense>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
