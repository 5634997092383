/* eslint max-statements: 0 */
/* eslint max-len:0 */
import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';
import {
  DEFAULT_USER_APP,
  FIVE,
  FOUR,
  isLocal,
  THREE, ZERO, getDomain
} from './constants';
import { getOrgDetailsForFivePartURL, getOrgDetailsForThreePartURL } from './lambdaAPIs';

const TOKEN_COOKIE_KEY = 'ziniertoken';
const ORGID_COOKIE_KEY = 'zinierorgid';
const LOGIN_ID_COOKIE_KEY = 'zinierloginid';
const ENV_COOKIE_KEY = 'zinierenv';

const TOKEN_DEV_COOKIE_KEY = 'ziniertoken_dev';
const ORGID_DEV_COOKIE_KEY = 'zinierorgid_dev';
const LOGIN_ID_DEV_COOKIE_KEY = 'zinierloginid_dev';
const ENV_DEV_COOKIE_KEY = 'zinierenv_dev';

const isDev = () => false;

const getOrgID = () => isDev() ? Cookies.get(ORGID_DEV_COOKIE_KEY)
  : Cookies.get(ORGID_COOKIE_KEY);
const getLoginID = () => isDev() ? Cookies.get(LOGIN_ID_DEV_COOKIE_KEY)
  : Cookies.get(LOGIN_ID_COOKIE_KEY);
const getToken = () => isDev() ? Cookies.get(TOKEN_DEV_COOKIE_KEY)
  : Cookies.get(TOKEN_COOKIE_KEY);
const getEnv = () => isDev() ? Cookies.get(ENV_DEV_COOKIE_KEY)
  : Cookies.get(ENV_COOKIE_KEY);

export const areCookiesAvailable = () => {
  return Boolean(getToken()) &&
  Boolean(getOrgID()) &&
  Boolean(getLoginID()) &&
  Boolean(getEnv());
};

// eslint-disable-next-line consistent-return
export const getLoginRedirectURL = async () => {
  let loginUrl;

  const { splitUrl, loginDomain, orgId, appName, envName, envLab } = await getLoginDetails();

  // Just in case if env or orgId doesnt get set due to some major error then go to login.domain
  if (isEmpty(envName) || isEmpty(orgId)) {
    return loginDomain;
  }
  if (splitUrl.length === FIVE) {
    // do not send orgId if its localhost
    loginUrl = isLocal(orgId)
      ? `${loginDomain}?app=${appName}&env=${envName}&redirect=${window.location.href}`
      : `${loginDomain}?org=${orgId}&app=${appName}&env=${envName}&redirect=${window.location.href}&lab=${envLab}`;
    return loginUrl;
  } else if (splitUrl.length === THREE || splitUrl.length === FOUR) {
    if (isLocal(orgId)) {
      loginUrl = `${loginDomain}?app=${appName}&redirect=${window.location.href}`;
      return Promise.resolve(loginUrl);
    }
    loginUrl = `${loginDomain}?org=${orgId}&app=${appName}&env=${envName}&redirect=${window.location.href}&lab=${envLab}`;
    return loginUrl;
  }
};

const getLoginDetails = async () => {
  let orgId, envName, envFromUrl, envLab;
  let appName = DEFAULT_USER_APP;
  let loginDomain = `https://login.${getDomain()}`;

  const [lengthOfParts, splitUrl] = getUrlParts();
  if (lengthOfParts === THREE) {
    [orgId] = splitUrl;
    const result = await getOrgDetailsForThreePartURL(orgId);
    ({ envName, envLab } = retriveOrgAndEnvName(result));
  } else if (lengthOfParts === FOUR) {
    [orgId, appName] = splitUrl;
    const result = await getOrgDetailsForThreePartURL(orgId);
    ({ envName, envLab } = retriveOrgAndEnvName(result));
  } else if (lengthOfParts === FIVE) {
    [orgId, appName, envFromUrl] = splitUrl;
    const result = await getOrgDetailsForFivePartURL(orgId, envFromUrl);
    ({ envName, envLab } = retriveOrgAndEnvName(result));
    if (envName) {
      loginDomain = `https://login.${envName}.${getDomain()}`;
    }
  }
  return { splitUrl, loginDomain, orgId, appName, envName, envLab };
};

const getUrlParts = () => {
  const host = window.location.host;
  const splitUrl = host.split('.');

  if (!host.includes('.')) {
    return [ZERO, []];
  }
  return [splitUrl.length, splitUrl];
};
function retriveOrgAndEnvName (result) {
  let envName, envLab;
  if (result && result.data) {
    envName = result.data.envName;
    envLab = result.data.envUrl;
  }
  return { envName, envLab };
}
