//
import json from './data';
import styles from './styles.module.css';
import Lottie from 'react-lottie';

export function Loader (props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: json,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <div className={
      // eslint-disable-next-line
      props.type === 'fullScreen' ? styles.loaderWrapper : ''}>
      <div className={styles.loaderDiv}>
        <Lottie
          options={defaultOptions}
        />
      </div>
    </div>

  );
}
