import { useEffect } from 'react';
import { getLoginRedirectURL } from '../../utilities/checkCookie';
import { Loader } from '../../assets/Loader/Loader';

export function RedirectToLogin () {
  useEffect(() => {
    (async function () {
      window.location = await getLoginRedirectURL();
    })();
  }, []);

  return <Loader type='fullScreen'/>;
}
